import { Fab } from "@mui/material";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";

const BookNowButton = () => {
  return (
    <Fab
      color="primary"
      aria-label="book"
      variant="extended"
      sx={{
        position: "fixed",
        bottom: (theme) => theme.spacing(3),
        right: (theme) => theme.spacing(3),
        zIndex: 1300,
      }}
    >
      <AnchorLink
        to="/book/#booking"
        gatsbyLinkProps={{
          style: { color: "white", textDecoration: "none", minWidth: "100%" },
        }}
      >
        Book now
      </AnchorLink>
    </Fab>
  );
};

export default BookNowButton;
