import { Avatar, Grid, Typography, useTheme } from "@mui/material";
import { graphql } from "gatsby";
import React from "react";
import BookNowButton from "../components/BookNowButton";
import Footer from "../components/Footer";
import PageSection from "../components/PageSection";
import TopBar from "../components/TopBar";
import { greys } from "../theme";
import "./BlogTemplate.css";

export default function BlogTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const theme = useTheme();
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  console.log("frontmatter", frontmatter);
  return (
    <>
      <TopBar />
      <div style={{ paddingTop: "90px", backgroundColor: greys[0] }} />
      {frontmatter && (
        <PageSection
          id="blog"
          title={frontmatter.longTitle}
          bgColor={greys[0]}
          labelColor="black"
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: "10px" }}
          >
            <Grid item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar email={frontmatter.email} name={frontmatter.author} />
                <Typography style={{ marginLeft: "20px" }} variant="subtitle1">
                  {frontmatter.author}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" display="block">
                {frontmatter.date.toUpperCase()} |{" "}
                {frontmatter.category.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>

          <div
            style={{
              overflowWrap: "break-word",
              "& p": theme.typography.body1,
              "& ul": theme.typography.body1,
              "& h1": theme.typography.h3,
              "& h2": theme.typography.h4,
              "& h3": theme.typography.h5,
              "& h4": theme.typography.h6,
              "& h5": theme.typography.subtitle1,
              "& h6": theme.typography.subtitle2,
              "& .footnotes": theme.typography.caption,
              "& .footnotes p": theme.typography.caption,
              "& .footnotes a": {
                color: "black",
              },
            }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </PageSection>
      )}
      <BookNowButton />
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query ($slug: String!) {
    blogBackground: file(relativePath: { eq: "swimming.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 5192) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            slug
            shortTitle
            category
            digest
          }
        }
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        slug
        category
        longTitle
        shortTitle
        author
        email
      }
    }
  }
`;
